import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../services/UserService';
import { addUser, updateUser } from '../../services/UserService';
import { toast } from 'react-toastify';
import { getCountryList, validatePassword, getAllCountryData, getAllStatesData, getAllCitiesData } from '../../services/CommonService';
import Select from 'react-select';
import { getAllCohortData, addCohortLearner, updateCohortLearner } from '../../services/CohortService';

export default function CreateLearner() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({user: {first_name: ""}});
    const [user, setUser] = useState({first_name: "", middle_name: "", last_name: "", email: "", password: "", status: "ACTIVE", role: "LEARNER", state: "", city: "", country: "USA", addr_line1: "", addr_line2: "", zip_code: "", phone_no: ""});
    const [countriesList, setCountriesList] = useState([]);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countryCode, setCountryCode] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [edit, setEdit] = useState(false);
    const [cohorts, setCohorts] = useState([]);
    const [selectedCohort, setSelectedCohort] = useState(0);
    const [returnToCohort, setReturnToCohort] = useState(false);
    useEffect(() => {
        const userDetails = getUserDetails();
        if(userDetails.token === undefined){
            navigate('/login');
        }else{
            setUserDetails(userDetails);
            let adminUser = sessionStorage.getItem('learnerEdit') !== undefined ? JSON.parse(sessionStorage.getItem('learnerEdit')) : {};
            let addLearnersInCohort = sessionStorage.getItem('addLearnersInCohort') !== undefined ? JSON.parse(sessionStorage.getItem('addLearnersInCohort')) : {};

            if(adminUser !== null && adminUser !== undefined && adminUser.email !== undefined){
                setUser(adminUser);
                setEdit(true);
                if(adminUser.cohort_id !== undefined && adminUser.cohort_id !== null){
                    setSelectedCohort(adminUser.cohort_id);
                }
                sessionStorage.removeItem('learnerEdit');
            }else{
                adminUser = {}
            }
            if(addLearnersInCohort !== null && addLearnersInCohort !== undefined && addLearnersInCohort.id !== undefined){
                setUser({
                    ...user,
                    ["cohort_id"]: addLearnersInCohort.id
                });
                setReturnToCohort(true);
                sessionStorage.removeItem('addLearnersInCohort');
            }
            getCountries(adminUser);
            getCohorts(userDetails.token);
        }
    },[]);

    const getCohorts = async (token) => {
        const cohortsData = await getAllCohortData(token);
        if(cohortsData.error){
            return [];
        }else{
            let ch = [];
            for(let i=0;i<cohortsData.data.length; i++){
                let chObj = {value: cohortsData.data[i].id, label: cohortsData.data[i].cohort_desc + " - "+ (cohortsData.data[i].org_name !== null && cohortsData.data[i].org_name !== undefined ? cohortsData.data[i].org_name : "")};
                ch.push(chObj);
            }
            setCohorts(ch);
        }
    };

    const getCountries = async (adminUser) => {
        const userDetails = getUserDetails();
        if(userDetails.token !== undefined){
            setLoading(true);
            const response = await getAllCountryData(userDetails.token);
            const countryList = response.data;
            setCountriesList(countryList);
            let cntry = [];
            let cntryCode = {};
            for(let i=0; i<countryList.length; i++){
                cntry.push({label: countryList[i].country, value: countryList[i].country});

                cntryCode[countryList[i].country] = countryList[i].phone_cd
            }
            setCountries(cntry);
            setCountryCode(cntryCode);
            await changeCountryValue("country", user.country, countryList);
            if(adminUser.state !== undefined && adminUser.state !== '' && adminUser.state !== null){
                await changeCountryValue("state", adminUser.state, countryList);
            }
            setLoading(false);
        }else{
            toast.info("Please login again");
            navigate('/login');
        }
    };

    const handleCohortValueChange = async (value) => {
        setUser({
            ...user,
            ["cohort_id"]: value
        });
    };

    const handleCountryValueChange = async (name, value) => {

        if(name === 'country'){
            setUser({
                ...user,
                ["state"]: undefined,
                [name]: value,
                ["city"]: undefined
            });  
        } else if(name === 'state'){
            setUser({
                ...user,
                [name]: value,
                ["city"]: undefined
            });  
            
        }else{
            setUser({
                ...user,
                [name]: value
            });
        }
        await changeCountryValue(name, value, countriesList);
    };

    const changeCountryValue = async (name, value, countryList) => {
        const userDetails = getUserDetails();
        if(name === 'country'){
            let values = [];
            const st = await getAllStatesData(userDetails.token, value);
            
            for(let i=0; i<st.data.length; i++){
                values.push({label: st.data[i].state, value: st.data[i].state}); 
            }
            setStates(values);
        }
        if(name === 'state'){
            let values = [];
            const st = await getAllCitiesData(userDetails.token, value);     
            for(let i=0; i<st.data.length; i++){
                values.push({label: st.data[i].city, value: st.data[i].city}); 
            }
            setCities(values);
        }
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setUser({
          ...user,
          [name]: value,
        });
    };

    const validateUser = (user) => {
        return user.email === undefined || user.email === null || user.email === ''
        || user.password === undefined || user.password === null || user.password === '' || user.cohort_id === null || user.cohort_id === undefined || user.cohort_id === '';
    };

    const addAdmin = async () => {
        const userDetails = getUserDetails();
        const isValid = validateUser(user);
        if(user.phone_no !== '' && user.phone_no !== undefined && user.phone_no !== null && user.phone_no.length > 10){
            toast.warn("Phone number length is greater than 10 digits!");
        }
        else if(user.zip_code !== '' && user.zip_code !== undefined && user.zip_code !== null && user.zip_code.length > 5){
            toast.warn("Zip Code length is greater than 5 digits!");
        }
        else if(!validatePassword(user.password)){
            toast.error("Password is not Valid. Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a numeral, and a special character.");
        }
        else if(!isValid){
            if(userDetails.token){
                setLoading(true);
                let resp = await addUser(userDetails.token, user);
                setLoading(false);
                if(resp.error===false){
                    toast.success("User with role Learner is created!");
                    const insertId = resp.id;
                    let resp1 = await addCohortLearner(userDetails.token, {user_id: insertId, cohort_id: user.cohort_id});
                }else{
                    toast.error(resp.data);
                }
              }else{
                    toast.info("Please login again");
                    navigate('/login');        
              }
        }else{
            toast.error("Mandatory Fields are empty");
        }
        
    };

    const updateAdmin = async () => {
        const userDetails = getUserDetails();
        const isValid = validateUser(user);
        if(user.phone_no !== '' && user.phone_no !== undefined && user.phone_no !== null && user.phone_no.length > 10){
            toast.warn("Phone number length is greater than 10 digits!");
        }
        else if(user.zip_code !== '' && user.zip_code !== undefined && user.zip_code !== null && user.zip_code.length > 5){
            toast.warn("Zip Code length is greater than 5 digits!");
        }
        else if(!isValid){
            if(userDetails.token){
                setLoading(true);
                let resp = await updateUser(userDetails.token, user);
                setLoading(false);
                if(resp.error===false){
                    toast.success("Changes updated successfully!");
                    if(user.cohort_learner_id !== null){
                        if(parseInt(selectedCohort) !== parseInt(user.cohort_id)){
                            let resp1 = await updateCohortLearner(userDetails.token, {user_id: user.id, cohort_id: user.cohort_id, id: user.cohort_learner_id, status: user.status});
                        }
                    }else{
                        let resp1 = await addCohortLearner(userDetails.token, {user_id: user.id, cohort_id: user.cohort_id});
                    }
                }else{
                    toast.error(resp.data);
                }
              }else{
                    toast.info("Please login again");
                    navigate('/login');        
              }
        }else{
            toast.error("Mandatory Fields are empty");
        }
        
    };

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            {loading ? (
                <div className="spinner-container">
                <img src='images/loading.gif' alt='GIF' />
                </div>
            ) : (
                <div className='lg:py-10 lg:px-12 py-10 px-6'>
                    <h3 className='text-2xl text-secondary text-center inter-700 mb-6 md:mb-4 lg:mb-3 lg:hidden'>Add New Learner</h3>
                    <div className='back-btn flex justify-start mb-5 md:mb-8'>
                        <a onClick={() => navigate(returnToCohort? '/admin/managecohort' : '/admin/managelearner')} className='cursor-pointer w-auto bg-tertiary hover:bg-black flex items-center justify-center px-6 h-7 2xl:h-8 text-white inter-500 text-xs 2xl:text-lg rounded-3xl lg:rounded-full transition duration-300 button-shadow'>Back</a>
                    </div>
                    <form className='w-full md:w-4/5'>
                        
                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-[65%] mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Cohort <span className='text-[#f00]'>*</span></label>
                                <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                    options={cohorts}
                                    value={cohorts.find(option => option.value === user.cohort_id)}
                                    onChange={(values) => handleCohortValueChange(values.value)}
                                />
                            </div>
                        </div>

                        <div className='flex justify-between flex-wrap'>
                            <div className='form-field w-full md:w-[30%] mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>First Name <span className='text-[#f00]'>*</span></label>
                                <input type='text' name="first_name" value={user.first_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full md:w-[30%] mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Middle Name</label>
                                <input type='text' name="middle_name" value={user.middle_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                            <div className='form-field w-full md:w-[30%] mb-5 md:mb-8'>
                                <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Last Name</label>
                                <input type='text' name="last_name" value={user.last_name} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                            </div>
                        </div>
                        <div className='w-full md:w-4/5'>
                            <div className='flex justify-between flex-wrap'>
                                <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Email <span className='text-[#f00]'>*</span></label>
                                    <input type='email' name="email" value={user.email} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>
                                <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Password <span className='text-[#f00]'>*</span></label>
                                    <div className='relative'>   
                                        {edit ? (
                                        <input type={showPassword ? 'text' : 'password'} name="password" value={user.password} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required disabled/>

                                        ) : (
                                            <>
                                        <input type={showPassword ? 'text' : 'password'} name="password" value={user.password} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                        <button onClick={() => handleShowPassword()} type='button' className='absolute right-4 top-0 h-full w-5 flex items-center justify-center'>
                                            <img className={showPassword ? 'pass-view-btn' : 'pass-hide-btn opacity-50'} src={showPassword ? '../images/pass-view.webp' : '../images/pass-hide.webp'} alt='password hide'/>
                                        </button>
                                        </>
                                        )}

                                    </div>
                                </div>
                            </div>

                            <div className='flex justify-between flex-wrap'>
                                <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Country <span className='text-[#f00]'>*</span></label>
                                    <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                        options={countries}
                                        value={countries.find(option => option.value === user.country)}
                                        onChange={(values) => handleCountryValueChange("country", values.value)}
                                    />
                                </div>
                                {/* <div className='form-field w-full md:w-[45%] mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Phone <span className='text-[#f00]'>*</span></label>
                                    <div className='relative'>
                                        <input type='number' name="phone_no" value={user.phone_no} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 pr-6 pl-16  2xl:pr-10 transition duration-300' required/>
                                        <div className='absolute left-0 top-0 w-14'>
                                            <input type='text' name="country_code" value={countryCode[user.country]} onChange={handleValueChange} placeholder='' className='inter-400 bg-transparent text-black text-base border-r border-[#bbb] focus:border-[#B44D71] text-center h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-2 transition duration-300 inline-block' required disabled/>
                                        </div>
                                    </div>  
                                </div> */}
                            </div>

                            <div className='flex justify-between flex-wrap'>
                                <div className='form-field w-full mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>State <span className='text-[#f00]'>*</span></label>
                                    <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                        options={states}
                                        value={states.find(option => option.value === user.state)}
                                        onChange={(values) => handleCountryValueChange("state", values.value)}
                                    />
                                </div>
                                <div className='form-field w-full mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>City <span className='text-[#f00]'>*</span></label>
                                    <Select className='inter-400 bg-[#fbfbfb] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md'
                                        options={cities}
                                        value={cities.find(option => option.value === user.city)}
                                        onChange={(values) => handleCountryValueChange("city", values.value)}
                                    />
                                </div>
                                {/* <div className='form-field w-full mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Address 01 <span className='text-[#f00]'>*</span></label>
                                    <input type='text' name="addr_line1" value={user.addr_line1} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>
                                <div className='form-field w-full mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Address 02 </label>
                                    <input type='text' name="addr_line2" value={user.addr_line2} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>  */}
                                <div className='form-field w-full mb-5 md:mb-8'>
                                    <label className='block mb-2 2xl:mb-3 text-base lg:text-xl 2xl:text-3xl inter-500 text-black'>Zip Code <span className='text-[#f00]'>*</span></label>
                                    <input type='number' name="zip_code" value={user.zip_code} onChange={handleValueChange} placeholder='' className='inter-400 bg-[#EFEFEF] text-black text-base border-2 border-[#bbb] focus:border-[#B44D71] rounded-md h-10 2xl:h-16 outline-0 w-full py-2.5 2xl:py-5 px-6 2xl:px-10 transition duration-300' required/>
                                </div>
                            </div>

                            <div className='flex flex-wrap'>
                                <div className='customer-radio-sngl w-full md:w-max mr-5 md:mr-8 mb-5 md:mb-8'>
                                    <input type="radio" id="suspended" name="status" onChange={handleValueChange} value='SUSPENDED' checked={user.status === "SUSPENDED"} />
                                    <label htmlFor="suspended">Suspended</label>
                                </div>
                                <div className='customer-radio-sngl w-full md:w-max mr-5 md:mr-8 mb-5 md:mb-8'>
                                    <input type="radio" id="active" name="status" onChange={handleValueChange} value='ACTIVE' checked={user.status === "ACTIVE"} />
                                    <label htmlFor="active">Active</label>
                                </div>
                                <div className='customer-radio-sngl w-full md:w-max mr-5 md:mr-8 mb-5 md:mb-8'>
                                    <input type="radio" id="cancelled" name="status" onChange={handleValueChange} value='CANCELLED' checked={user.status === "CANCELLED"} />
                                    <label htmlFor="cancelled">Cancelled</label>
                                </div>
                            </div>
                        </div>
                        <div className='flex text-center items-center mt-6 2xl:mt-10'>
                            {edit ? (
                                <button onClick={() => updateAdmin()} type="button" className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Update Changes</button>

                            ) : (
                                <button onClick={() => addAdmin()} type="button" className='w-full md:w-auto bg-secondary hover:bg-black flex items-center justify-center px-10 h-10 2xl:h-16 text-white inter-700 text-base 2xl:text-2xl rounded-3xl lg:rounded-full transition duration-300 button-shadow'>+ Add Learner</button>
                            )}
                        </div>
                    </form>

                </div>
            )}
        </div>
    );
}