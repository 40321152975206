import { API_URL, GEOCODING_API_KEY } from "../utility/constants";
import axios from 'axios';


export async function getCountryList(token) {
    try{
        const response = await fetch(API_URL + "admin/country/getAllCountryMasterData", {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllCountryData(token) {
    try{
        const response = await fetch(API_URL + "admin/country/getAllCountryData", {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllStatesData(token, country) {
    try{
        const response = await fetch(API_URL + "admin/country/getAllStatesData?country="+country, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export async function getAllCitiesData(token, state) {
    try{
        const response = await fetch(API_URL + "admin/country/getAllCitiesData?state="+state, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }catch(error) {
        return {error: true, data: "Some error occurred!"};
    }
};

export function formatSixDigitNumber(num) {
    if(num === undefined){
        return "";
    }
    let numStr = num.toString();
    while (numStr.length < 6) {
      numStr = '0' + numStr;
    }
    return numStr;
};

export function capitalizeFirstLetter(string){
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export async function fetchCoordinates (address) {
    try {
        const geocodePromises = address.map(addrDetail =>
                axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
                params: {
                    address: addrDetail.addr,
                    key: GEOCODING_API_KEY  // Replace with your Geocoding API key
                }
            })
        );

        const responses = await Promise.all(geocodePromises);
        const newPlaces = responses.map((response, index) => {
            const location = response.data.results[0]?.geometry?.location;
            return location
            ? { position: { lat: location.lat, lng: location.lng }, label: address[index].label, cohort_id: address[index].cohort_id  }
            : null;
        }).filter(place => place !== null);

        return newPlaces;
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
};

export function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
  
    // Convert formattedDate to include ordinal suffixes
    const day = date.getDate();
    const suffix = (day) => {
      if (day >= 11 && day <= 13) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
    
    return formattedDate.replace(day, day + suffix(day));
};

export function getTodayDateInUTC () {
    const today = new Date();
    
    // Extract year, month, and day
    const year = today.getUTCFullYear();
    const month = String(today.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(today.getUTCDate()).padStart(2, '0');
  
    // Format date as "yyyy-mm-dd"
    return `${year}-${month}-${day}`;
};

export function getRandomHexColor() {
    // Generate a random number between 0 and 0xFFFFFF (hexadecimal for 16777215)
    const randomColor = Math.floor(Math.random() * 0xFFFFFF);
    
    // Convert the random number to a hexadecimal string
    const hexColor = `#${randomColor.toString(16).padStart(6, '0')}`;
    
    return hexColor;
}

export function formatDateInText(timestamp) {
    const date = new Date(timestamp);

    // Format the date
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
}

export function validatePassword (pwd) {
    const lengthCheck = pwd.length >= 8;
    const uppercaseCheck = /[A-Z]/.test(pwd);
    const lowercaseCheck = /[a-z]/.test(pwd);
    const numeralCheck = /[0-9]/.test(pwd);
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/.test(pwd);

    let isValid = lengthCheck && uppercaseCheck && lowercaseCheck && numeralCheck && specialCharCheck;
    return isValid;
};
